<!--
 * @Author: your name
 * @Date: 2022-04-02 17:40:49
 * @LastEditTime: 2022-04-02 17:54:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\user\components\twobox\index.vue
-->
<template>
  <div class="twobox">
   <itembox :info="item" v-for="(item,index) in itemlist" :key="index" @icon="icon"></itembox>
  </div>
</template>
<script>
import itembox from "@/views/user/components/twobox/components/index.vue"
export default {
  data() {
    return {
      itemlist:[
        {
          title:"总余额",
          basie:"52%",
          front:"",
          after:"",
          icon:true,
          id:1
        },
        {
          title:"已绑定卡片",
          basie:"43%",
          num:"",
          icon:false,
          id:2
        }
      ],  // 循环创建item
    }
  },
  watch:{
    userInfo:{
      handler(newValue,oldValue){
       let str = newValue.c_balance+""
       let num = str.indexOf(".")
       if(num != -1){
         this.itemlist[0].front = str.substring(0,num)
         this.itemlist[0].after = str.substring(num,str.length)
       }
       
       this.itemlist[1].num = newValue.devices
      }
    },
    deep:true
  },
  props:{
    userInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  methods:{
    icon(){
      this.$emit("icon")
    }
  },
  components:{
      itembox
  }
}
</script>
<style lang="less" scoped>
.twobox{
  width: 100%;
  margin: -24px auto 15px;
  display: flex;
  justify-content: space-between;
  height: 72px;
}
</style>