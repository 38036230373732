<!--
 * @Author: your name
 * @Date: 2022-04-01 10:56:18
 * @LastEditTime: 2022-04-28 09:18:03
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\user\index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-01 10:49:26
 * @LastEditTime: 2022-04-01 10:50:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\guide\index.vue
-->
<template>
  <div class="user" :class="{padding:ispadding}">
    <div class="userinfo">
      <userbox :userinfo="userinfo" @buyMeal="buyMeal"></userbox>
    </div>
    <div class="all">
      <twobox :userInfo="userinfo" @icon = "newswifiShow = true"></twobox>
      <!-- <funbox @loginpath=loginpath($event) :userinfo = "userinfo" @isswitch="newswifiShow = true"></funbox> -->
      <codebox @showcode ="showcode"></codebox>
      <bottombox @nopath="nopath"></bottombox>
       <other></other>
    </div>
    <qrcode :showqrcode="showqrcode" @clkqrcode = "clkqrcode($event)"></qrcode>
    <qrcode :showqrcode="entCodeShow" @clkqrcode = "clkshow($event)" :class="{isentCode:entCode}">
      <img :src="entCode" alt="" class="qrcode-img">
    </qrcode>
    <newswifi :show="newswifiShow" @clkOverlay="newswifiShow = !newswifiShow" @change="change"></newswifi>
  </div>
</template>
<script>
import isweixin from "@/untils/isweixin"
import userbox from "@/views/user/components/userbox/index.vue"
import codebox from "@/views/user/components/codebox/index.vue"
import bottombox from "@/views/user/components/bottombox/index.vue"
import qrcode from "@/components/qrcode/index.vue"
import newswifi from "@/components/newswifi/index.vue"
import mixin from "@/mixin/mixin.js"
import twobox from "@/views/user/components/twobox/index.vue"
import other from "@/views/user/components/other/index.vue"
export default {
  data() {
    return{
      showqrcode:false,  // 公众号弹出层
      userinfo:{},  // 用户的信息,
      // surflow:{},  // 用户的设备剩余流量
      entCode:require("@/assets/images/guide/qrcode.jpg"),  // 企业微信路径
      entCodeShow:false,  // 企业微信是否`展示
      newswifiShow:false,  // 新的wifi弹窗是否显示(用于切换wifi设备)
      windowWidth: document.documentElement.clientHeight,  //实时屏幕宽度
      ispadding:false,  // 是否添加额外的内边距
      weixin:false
    }
  },
  watch:{
    windowWidth:{
      handler(newvalue,oldvalue){
        if(newvalue <= 667){
          this.ispadding = true
        }
      },
      deep:true,
      immediate:true
    }
  },
  mixins:[mixin],
  components:{
    userbox,
    codebox,
    bottombox,
    qrcode,
    newswifi,
    twobox,
    other
  },
  mounted(){
    this.weixin =isweixin()
    document.querySelector('body').setAttribute('style', 'background-color:#f5f8fc')
    this.getmyCenter()
    // this.getoffinfo()
    this.getUrl()
  },
  methods:{
    // 点击购买套餐
    buyMeal(){
      let data = {
        iccid:sessionStorage.getItem("iccid"),
        phone:sessionStorage.getItem("phone"),
        path:"/selectpack"
      }
      this.isopenid(data)
    },
    // 切换wifi设备
    change(){
      // this.surflow = {}
      this.newswifiShow = !this.newswifiShow
      this.getmyCenter()
      this.getoffinfo()
    },
    // 展示企业微信弹窗
    clkshow($event){
      this.entCodeShow = $event
    },
    // 如果是企业微信按钮被点击了
    nopath(){
     this.url = this.entCode
     this.entCodeShow = true
    },
    // 获取企业微信
    getUrl(){
      this.getWechatQRcode().then(res=>{
        if(res.data.code == 1){
          this.entCode = res.data.info.wechat_img
        }
      })
    },
    // 获取页面信息
    getmyCenter(){
      let data = {
        phone:sessionStorage.getItem("phone"),
        iccid:sessionStorage.getItem("iccid"),
        openid:sessionStorage.getItem('openid')
      }
      if(!this.weixin){
          delete(data.openid)
      }

      this.myCenter({data}).then(res =>{
        this.userinfo = res.data.info
        window.localStorage.setItem("headerImg",this.userinfo.headimgurl)
      })
    },
     // 根据iccid获取当前官方实名信息
    // getoffinfo(){
    //   let data ={
    //     iccid:sessionStorage.getItem("iccid")
    //   }
    //   this.officialState({data}).then(res =>{
    //     if(res.data.code == 1){
    //       this.surflow = res.data.info
    //     }else{
    //       this.$toast(`${res.data.msg}`)
    //     }
    //   })
    // },
    // 关闭弹出层
    clkqrcode($event){
      this.showqrcode = $event
    },
    // 打开弹出层
    showcode(){
      this.url = require("@/assets/images/guide/qrcode.jpg")
      this.showqrcode = true
    }
  }
}
</script>
<style lang="less" scoped>
.padding{
 padding-bottom: 50px;
}
.user{
  width: 100%;
  min-height: 100vh;
  .userinfo{
  width: 100%;
  height: 152px;
  background-image: url("~@/assets/images/user/bguser.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.all{
  width: 92%;
  margin: 0 auto ;
}
.qrcode-img{
  width: 100%;
  display: block;
}
.isentCode{
 /deep/.qrcode{
   margin: 0 auto 0;
 }
}
/deep/.van-radio__icon{
  display: none;
}
}
</style>